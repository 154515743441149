import { useState } from 'react';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { AiOutlineLoading } from 'react-icons/ai';
import axios from '../../utilities/axios';

const ImageGallery = ({ images }) => {
    const [previewImage, setPreviewImage] = useState(null);
    const [likedImages, setLikedImages] = useState({});
    const [loadingImages, setLoadingImages] = useState({});

    const openPreview = (image) => {
        setPreviewImage(image);
    };

    const closePreview = () => {
        setPreviewImage(null);
    };

    const toggleLike = async (image) => {
        const imageId = image.id;
        const isCurrentlyLiked = likedImages[imageId];

        setLoadingImages((prev) => ({
            ...prev,
            [imageId]: true,
        }));

        try {
            const endpoint = isCurrentlyLiked
                ? `/sliders/disLike/${imageId}`
                : `/sliders/like/${imageId}`;

            const response = await axios.get(endpoint);

            if (response.data) {
                setLikedImages((prev) => ({
                    ...prev,
                    [imageId]: !isCurrentlyLiked,
                }));
            } else {
                console.error("Failed to toggle like/dislike:", response.statusText);
            }
        } catch (error) {
            console.error("Error toggling like/dislike:", error);
        } finally {
            setLoadingImages((prev) => ({
                ...prev,
                [imageId]: false,
            }));
        }
    };

    const renderMasonryLayout = () => {
        // First 6 images in masonry layout
        const masonryImages = images.slice(0, 6);
        // Remaining images for the row
        const remainingImages = images.slice(6);

        return (
            <div>
                {/* Masonry layout for first 6 images */}
                <div className="grid grid-cols-2 gap-2 px-4">
                    <div>
                        <img
                            className="w-full rounded-xl shadow h-[414px] object-cover cursor-pointer"
                            src={masonryImages[0]?.image}
                            alt={`Image ${masonryImages[0]?.id}`}
                            onClick={() => openPreview(masonryImages[0])}
                        />
                    </div>
                    <div className="space-y-2">
                        <img
                            className="w-full rounded-xl shadow h-[203px] object-cover cursor-pointer"
                            src={masonryImages[1]?.image}
                            alt={`Image ${masonryImages[1]?.id}`}
                            onClick={() => openPreview(masonryImages[1])}
                        />
                        <img
                            className="w-full rounded-xl shadow h-[203px] object-cover cursor-pointer"
                            src={masonryImages[2]?.image}
                            alt={`Image ${masonryImages[2]?.id}`}
                            onClick={() => openPreview(masonryImages[2])}
                        />
                    </div>
                    <div className="space-y-2">
                        <img
                            className="w-full rounded-xl shadow object-cover h-[203px] cursor-pointer"
                            src={masonryImages[4]?.image}
                            alt={`Image ${masonryImages[4]?.id}`}
                            onClick={() => openPreview(masonryImages[4])}
                        />
                        <img
                            className="w-full rounded-xl shadow object-cover h-[203px] cursor-pointer"
                            src={masonryImages[5]?.image}
                            alt={`Image ${masonryImages[5]?.id}`}
                            onClick={() => openPreview(masonryImages[5])}
                        />
                    </div>
                    <div>
                        <img
                            className="w-full rounded-xl shadow object-cover h-[414px] cursor-pointer"
                            src={masonryImages[3]?.image}
                            alt={`Image ${masonryImages[3]?.id}`}
                            onClick={() => openPreview(masonryImages[3])}
                        />
                    </div>
                </div>

                {/* Render remaining images in rows */}
                {remainingImages.length > 0 && (
                    <div className="flex flex-row items-center justify-center gap-2 mt-2">
                        {remainingImages.map((image, index) => (
                            <img
                                key={image.id}
                                className="h-[203px] w-[196px] rounded-xl shadow object-cover cursor-pointer"
                                src={image.image}
                                alt={`Image ${image.id}`}
                                onClick={() => openPreview(image)}
                            />
                        ))}
                    </div>
                )}
            </div>
        );
    };

    const renderGridLayout = () => (
        <div className="grid grid-cols-2 gap-4 px-4">
            {images.map((image) => (
                <div key={image.id}>
                    <img
                        className="w-full h-[200px] rounded-xl shadow object-cover cursor-pointer"
                        src={image.image}
                        alt={`Image ${image.id}`}
                        onClick={() => openPreview(image)}
                    />
                </div>
            ))}
        </div>
    );

    return (
        <>
            {/* Image Preview Modal */}
            {previewImage && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-10 rounded-md"
                    onClick={closePreview}
                >
                    <div
                        className="relative max-w-4xl w-full p-4"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <img
                            src={previewImage.image}
                            alt="Preview"
                            className="w-full rounded-xl shadow object-fill max-h-[90vh] left-4 right-4"
                        />
                        <button
                            onClick={() => toggleLike(previewImage)}
                            className={`absolute top-8 right-10 w-10 h-10 flex justify-center items-center rounded-full shadow-lg transition-colors ${
                                likedImages[previewImage?.id] ? 'bg-white text-[#4c00ff]' : 'bg-white text-[#4c00ff]'
                            }`}
                        >
                            {loadingImages[previewImage?.id] ? (
                                <AiOutlineLoading className="animate-spin" />
                            ) : likedImages[previewImage?.id] ? (
                                <FaHeart />
                            ) : (
                                <FaRegHeart />
                            )}
                        </button>
                        <button
                            onClick={closePreview}
                            className="absolute top-8 left-10 w-10 h-10 flex justify-center items-center rounded-full shadow-lg bg-white"
                        >
                            <p className="text-lg text-[#4c00ff] items-center justify-center mt-1">x</p>
                        </button>
                        {previewImage.description && (
                            <div className="absolute bottom-4 left-4 right-4 p-4 bg-black bg-opacity-75 text-white">
                                <p className="text-center">{previewImage.description}</p>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {images.length >= 6 && images.length % 2 === 0 ? renderMasonryLayout() : renderGridLayout()}
        </>
    );
};

export default ImageGallery;