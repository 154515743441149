import { useState } from 'react';
import WhatsApp_icon from '../../assets/logos/WhatsApp_icon.svg';
import massenger from '../../assets/logos/massenger.svg';
import { getAnalytics, logEvent } from 'firebase/analytics';
import ReactPixel from 'react-facebook-pixel';
import Book from '../../components/Modals/Book';
const ActionButtons = ({ profile }) => {
  const analytics = getAnalytics(); // Initialize Firebase Analytics

  const [showBookingModal, setShowBookingModal] = useState(false);

  const data = {
    id : profile.id,
    name : profile.name
  }
  const handleWhatsAppClick = () => {
    logEvent(analytics, 'whatsapp_click',data);
    ReactPixel.trackCustom('doctor_whatsapp',data);
    let whatsappCide = profile?.whatsappCode ;

    window.open(`https://wa.me/${whatsappCide}`, '_blank');
  };

  const handleMessengerClick = () => {
    let messengerCode = profile?.messengerCode ;
    logEvent(analytics, 'messenger_click',data);
    ReactPixel.trackCustom('doctor_messenger',data); // Facebook Pixel tracking for WhatsApp click

    window.open(`https://m.me/${messengerCode}`, '_blank');
  };

  const handleReservationClick = () => {
    logEvent(analytics, 'doctor_reservation',data);
    ReactPixel.trackCustom('doctor_reservation',data); // Facebook Pixel tracking for doctor_reservation click
    setShowBookingModal(true)
  };

  return (
    <>
      {/* Fixed Container */}
      <div className='fixed bottom-10 left-0 px-6 w-[99%] bg-transparent p-4 z-1 animate-slideUp'>
                <div className='flex justify-center items-center gap-4'>
                  <button
                    onClick={() => {
                      handleReservationClick()
                    }}
                    className='flex-1 bg-[#4C00FF] text-white text-lg text-center py-3 rounded-lg font-bold'>
                    احجز موعداً الآن
                  </button>
                  <button
                    onClick={() => {
              handleWhatsAppClick()
                    }}
                    className='w-12 h-12 bg-[#34C759] rounded-lg flex items-center justify-center'
                    aria-label='WhatsApp'>
                    <img
                      src={WhatsApp_icon}
                      alt='WhatsApp'
                      className='w-8 h-8'
                    />
                  </button>



                  <button
                    onClick={() => {
               handleMessengerClick()
                    }}
                    className='w-12 h-12 bg-white rounded-lg flex items-center justify-center'
                    aria-label='Messenger'>
                    <img
                      src={massenger}
                      alt='Messenger'
                      className='w-8 h-8'
                    />
                  </button>
                </div>
              </div>

            
          


       {/* Booking Modal */}
       {showBookingModal && (
        <Book
          userId={profile.id}
          showModal={showBookingModal}
          setShowModal={setShowBookingModal}
        />
      )}

      {/* Add padding to prevent content from being hidden behind fixed buttons */}
      <div className="h-20" />
    </>
  );
};

export default ActionButtons;